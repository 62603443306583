<template>
  <div>
    <b-row>
      <b-col sm="8" xs="12">
        <h1 style="text-transform: capitalize">
          {{ getTitleByEntityType() }}
        </h1>
      </b-col>
      <b-col sm="4" xs="12" class="text-right" style="margin: auto">
        <b-button variant="primary" @click="add"> Lägg till </b-button>
      </b-col>
      <b-col md="7" sm="12" class="my-1">
        <b-form-group
          label-for="filter-input"
          label-align-sm="right"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Filtrera...."
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Rensa
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col v-show="totalRows > perPage" sm="12" md="5" class="my-1">
        <b-form-group
          label="Per sida"
          label-for="per-page-select"
          label-cols-sm="6"
          label-align-sm="right"
          class="mb-0"
        >
          <PaginationPageSizeSelect id="per-page-select" v-model="perPage" />
        </b-form-group>
      </b-col>

      <b-col v-show="totalRows > perPage" cols="12" class="my-1 text-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          class="my-0"
        />
      </b-col>
    </b-row>

    <!--TABLE-->
    <b-table
      v-if="!isBusy"
      :items="files"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="['description']"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      stacked="sm"
      show-empty
      small
      @filtered="onFiltered"
      @context-changed="onContextChanged"
    >
      <template #cell(id)="row">
        <div class="overflow-hidden">
          <img height="25" v-if="isImage(row.item.fileContentType)" width="25" :src="getImgUrl(row.item.id)" />
        </div>
      </template>
      <template #cell(description)="row">
        <div class="overflow-hidden">
          {{ row.item.description }}
        </div>
      </template>
      <template #cell(actions)="row">
        <!-- Open sidebar. -->
        <font-awesome-icon
          class="d-none d-sm-block text-secondary"
          icon="pen-square"
          size="lg"
          role="button"
          @click="edit(row.item)"
        />
        <b-button
          class="text-center d-block d-sm-none"
          block
          @click="edit(row.item)"
        >
          Mer
        </b-button>
        <!-- ./ Open sidebar. -->
      </template>
      <template #empty="">
        <h4 class="text-center">Inga resultat</h4>
      </template>
      <template #emptyfiltered="">
        <div class="text-center">
          <h4>Inga resultat</h4>
          <h6>
            <a href="#" @click="filter = ''">
              Klicka här för att rensa aktiv filtrering
            </a>
          </h6>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center mt-4">
          <font-awesome-icon
            class="align-middle"
            icon="spinner"
            size="2x"
            spin
          />
        </div>
      </template>
    </b-table>

    <!--SIDEBAR-->
    <GenericSidebar
      :visible.sync="sidebar.visible"
      :is-edit-mode.sync="sidebar.isEditMode"
      :fields="sidebar.fields"
      :item="sidebar.selected"
      @update:updateData="getData"
    >
      <template v-slot:header>
        <span v-if="sidebar.selected">
          {{ sidebar.selected.description }}
        </span>
      </template>
      <template v-slot:edit>
          <div v-if="sidebar.selected && !sidebar.isEditMode"
               class="card-body pt-0">
              <div class="card-text">
                  <dl class="row">
                      <dt class="col-12">Storlek</dt>
                      <dd class="col-12" style="word-break: break-all">
                          {{ sidebar.selected.fileSize | formatFileSize }}
                      </dd>
                  </dl>
              </div>
          </div>
          
          <DownloadFileButton v-if="sidebar.selected && !sidebar.isEditMode"
                              class="btn-block mb-2 border-gray-light"
                              :fileId="sidebar.selected.id" />

          <b-button v-if="!sidebar.isEditMode"
                    variant="secondary"
                    class="mb-2"
                    block
                    @click="sidebar.isEditMode = true">
              Ändra
          </b-button>
          <ManageFile v-if="sidebar.isEditMode"
                    :id="sidebar.selected.id"
                    :entity-type="entityType"
                    :entity-id="entityId"
                    @update:changed-edit-mode="onChangedEditMode" />
      </template>
    </GenericSidebar>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { get } from '@/helpers/api';
import ManageFile from '@/components/file/ManageFile';
import GenericSidebar from '@/components/GenericSidebar';
import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';
import { getDisplayText } from '@/helpers/entity-type.helper';
import { isImageContentType } from '@/helpers/file.helper';
import DownloadFileButton from '@/components/file/DownloadFileButton';

export default {
  name: 'ListFiles',
  components: {
    GenericSidebar,
    ManageFile,
    PaginationPageSizeSelect,
    DownloadFileButton
  },
  data() {
    return {
      sidebar: {
        selected: null,
        visible: false,
        isEditMode: false,
            fields: [{ key: 'fileName', label: 'Filnamn' }, { key: 'description', label: 'Beskrivning' }]
      },
      files: [],
      fields: [
        { key: 'id', label: '', sortable: true, sortDirection: 'desc' },
        {
          key: 'description',
          label: 'Beskrivning',
          sortable: true,
          sortDirection: 'desc'
        },
        { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      isBusy: true
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    sortOptions() {
      // Create an options list from our fields.
      return this.fields
        .filter((x) => x.sortable)
        .map((x) => {
          return { text: x.label, value: x.key };
        });
    },
    entityType() {
      return parseInt(this.$route.params.entityType);
    },
    entityId() {
      return parseInt(this.$route.params.entityId);
    }
  },
  async mounted() {
    // Fetch data.
    await this.getData();
  },
  methods: {
    onContextChanged(ctx) {
      if (ctx.perPage > this.totalRows)
        this.currentPage = 1;
    },
    async onChangedEditMode(item) {
      this.sidebar.selected = item;
      this.sidebar.isEditMode = false;
      const index = this.files.findIndex(x => x.id === item.id);
      this.files.splice(index, 1, item);
    },
    isImage(contentType) {
        return isImageContentType(contentType);
    },
    getTitleByEntityType() {
      return getDisplayText(this.entityType);
    },
    getImgUrl(fileId) {
      return `${process.env.VUE_APP_ROOT_API}File/Download/${fileId}`;
    },
      add() {
          this.sidebar.selected = { id: 0 };
          this.sidebar.visible = true;
          this.sidebar.isEditMode = true;
      },
    edit(item) {
      this.sidebar.selected = item;
      this.sidebar.visible = true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      },
    async getData() {
      this.isBusy = true;

      let action = this.entityId
        ? `GetAllBy/${this.entityType}/${this.entityId}`
        : `GetAllByType/${this.entityType}`;

      await get('File', action)
        .then((x) => {
          this.files = x.data;

          // Set the initial number of items.
          this.totalRows = this.files.length;

          this.isBusy = false;
        })
        .catch((x) => {
          // error handling.
        });
    }
  }
};
</script>
